<template>
  <CFooter :fixed="false">
    <div>
      <span>eatas</span>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://eatas-inc.com" target="_blank" rel="noopener noreferrer">eatas</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
<style scoped>
.c-footer {
  flex: 0 0 30px;
}
</style>
