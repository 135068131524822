<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img :src="userIcon" class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>
    <!-- <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Settings</strong>
    </CDropdownHeader> -->
    <CDropdownItem @click="toClientUserSetting"> <CIcon name="cil-user" /> ユーザー情報 </CDropdownItem>
    <CDropdownItem @click="toClientSetting"> <CIcon name="cil-settings" /> 設定 </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem @click="logout"> <CIcon name="cil-lock-locked" />ログアウト </CDropdownItem>
  </CDropdown>
</template>

<script>
import { encryption } from "@/utils/crypto";
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      authUser: "",
      userIcon: "",
    };
  },
  mounted() {
    this.userIcon = localStorage.getItem("photoURL")
      ? localStorage.getItem("photoURL")
      : require("../assets/img/user.png");
  },
  computed: {
    encryptedClientID() {
      return encryption(this.$store.state.clientUserData.clientData.clientID);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    toClientSetting() {
      this.$router.push(`/client-setting/${this.encryptedClientID}`);
    },
    toClientUserSetting() {
      this.$router.push(`/client-user-setting/${this.encryptedClientID}`);
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
.c-avatar-img {
  height: 20px;
  width: 20px;
}
</style>
