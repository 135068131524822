<template>
  <CHeader fixed with-subheader light>
    <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
    <CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('toggleSidebarDesktop')" />
    <!-- <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand> -->
    <CHeaderNav class="mr-4 ml-auto">
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <div>{{ userName }}</div>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import firebase from "firebase/app";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  data() {
    return {
      userName: "",
    };
  },
  async created() {
    this.userName = localStorage.getItem("authUserName");
    // await firebase.auth().onAuthStateChanged(async (user) => {
    //   if (user) {
    //     // User is signed in, see docs for a list of available properties
    //     // https://firebase.google.com/docs/reference/js/firebase.User
    //     var uid = user.uid;
    //     var authUser = user;
    //     // this.userName = user.displayName
    //     await this.$store.dispatch("setClientUserData", uid);
    //     console.log(this.$store.state.clientUserData.clientData.clientID);
    //     this.$store.commit("setAuthUser", authUser);
    //     localStorage.setItem("authUserName", authUser.displayName);
    //     localStorage.setItem("photoURL", authUser.photoURL);
    //   } else {
    //     // User is signed out
    //   }
    // });
  },
  // watch: {
  //   userName: {

  //   }
  // }
};
</script>
<style scoped>
.c-header,
.c-header-nav {
  min-height: 30px;
  height: 30px;
}
</style>
