export default [
  {
    _name: "CSidebarNav",
    _children: [
      // {
      //   _name: "CSidebarNavTitle",
      //   _children: ["〇〇"],
      // },
      {
        _name: "CSidebarNavItem",
        name: "ユーザー一覧",
        to: "/dashboard",
        icon: "cil-user",
      },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "お知らせ",
      //   to: "/",
      //   icon: "cil-bell",
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "ユーザーページ",
      //   to: "/user-page",
      //   icon: "cil-bell",
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "サンプル",
      //   to: "/user-page-sample",
      //   icon: "cil-bell",
      // },
    ],
  },
];
