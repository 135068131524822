export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "取引先一覧",
        to: "/client-list",
        icon: "cil-user",
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: '店舗一覧',
      //   to: '/shop-list',
      //   icon: 'cil-home',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'スタッフ一覧',
      //   to: '/staff-list',
      //   icon: 'cil-user',
      // },
      {
        _name: "CSidebarNavItem",
        name: "ユーザー一覧",
        to: "/dashboard",
        icon: "cil-user",
      },
    ],
  },
];
