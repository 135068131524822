<template>
  <CSidebar
    class="sidebar"
    size="sm"
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand to="/">
      <img class="c-sidebar-brand-full logo-img" src="@/assets/img/logo.png" />
      <img src="@/assets/img/logo.png" class="c-sidebar-brand-minimized logo-img-minimize" />
    </CSidebarBrand>
    <!-- {{ role }} -->
    <CRenderFunction v-if="role == ''" flat :content-to-render="$options.nav" />
    <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import _nav_eatas from "./_nav_eatas";

export default {
  name: "TheSidebar",
  nav,
  _nav_eatas,
  data() {
    return {
      role: "",
    };
  },
  created() {
    // this.role = this.$store.state.clientUserData.clientUserData.role;
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    // role() {
    //   return this.$store.state.clientUserData.clientUserData.role || ""
    // }
  },
};
</script>
<style scoped>
.sidebar {
  background: #172b4d;
}
.logo-img {
  height: 100%;
  padding: 0 0.5rem;
  margin-right: auto;
}
.logo-img-minimize {
  height: 100%;
  padding: 0 0.5rem;
  margin-right: auto;
  max-width: 80px;
}
.c-sidebar-brand {
  max-height: 30px;
}
</style>
