<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.path"></router-view>
        </transition>
      </div>
      <TheFooter class="footer" />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import firebase from "firebase/app";

const db = firebase.firestore();

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  created() {},
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.c-body {
  width: 100%;
  height: 100%;
  /* background-color: salmon; */
}
</style>

<style>
.footer {
  z-index: 100;
}
.border-radius {
  border-radius: 10px;
  padding: 0.5rem;
  margin: 0.3rem;
}
.pink {
  background: rgb(255, 134, 107);
  /* #ff866b */
  color: #fff;
}
.blue {
  background: rgb(107, 137, 255);
  /* #6b89ff */
  color: #fff;
}
.yellow {
  background: rgb(252, 184, 101);
  /* #fcb865 */
  color: #fff;
}
.green {
  background: rgb(53, 190, 130);
  /* #35be82 */
  color: #fff;
}
.red {
  background: rgb(255, 4, 0);
  /* #35be82 */
  color: #fff;
}
.btn {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 3px;
  border: 0;
  font-weight: bold;
  transition: 0.3s;
  line-height: 14px;
}
.btn:hover {
  opacity: 0.7;
  color: #fff;
}
</style>
